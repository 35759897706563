.NavBar {
  width: 120px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.sideNav {
  background-color: #24506a;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding-top: 1vw;
}
.side-link {
  text-decoration: none;
}

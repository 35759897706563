.iframePage {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.iframe {
  width: 98%;
  height: 98%;
}
.close-iframe {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: white;
  border: 1px solid #3bafdf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw;
  color: #3bafdf;
  -webkit-box-shadow: 0px 0px 15px 0px #000000; 
box-shadow: 0px 0px 15px 0px #000000;
}

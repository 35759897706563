.LoginPage {
  width: 100vw;
  height: 100vh;
  background-color: #f3f6fa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.loginContent {
  // width: 35%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 3vw;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.login-logo-wrapper {
  // height: 30%;
  // width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 250px;
  }

  h1 {
    direction: ltr;
  }
}

.login-box {
  width: 100%;
  height: 80%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3vw 0 3vw 0;
}
.login-header {
  font-size: 1.7vw;
  font-weight: bold;
  margin: 0;
}
.inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40%;
}
.input-wrapper {
  width: 60%;
  height: 35%;
  //background-color: #f5f5f5;
  border-radius: 10px;
  padding: 0 1vw 0 1vw;
  border: 2px solid #f5f5f5;
}
.name-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1vw;
}
.login-input {
  outline: none;
  border: none;
  width: 100%;
  height: 90%;
  font-family: inherit;
  background-color: transparent;
  font-size: 1.1vw;
}
.login-btn {
  width: 60%;
  height: 13%;
  border-radius: 10px;
  font-family: inherit;
  background: linear-gradient(270deg, #00a6d7 -2.65%, #32b9d2 110.61%);
  border: none;
  outline: none;
  color: white;
  font-size: 1.1vw;
  font-weight: bold;
  cursor: pointer;
}
.password-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
  }
}
.closeEye {
  filter: invert(64%) sepia(11%) saturate(0%) hue-rotate(138deg) brightness(90%)
    contrast(92%);
}
.openEye {
  filter: invert(0%) sepia(0%) saturate(2%) hue-rotate(174deg) brightness(103%)
    contrast(101%);
}
.error-input {
  border: 1.5px solid #ed4c5c !important;
  input {
    color: #ed4c5c !important;
  }
}
.errorText {
  color: #ed4c5c;
  margin: 0;
  width: 60%;
  font-size: 1vw;
}
.name-error {
  margin-top: -0.5vw;
}

.reports-page {
	// width: %95;
	width: 100%;
	// height: 100%;
	transition: padding-right 0.2s;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	// padding-left: 50px;
}

.shrink-page {
	padding-right: 235px;
	// width: 80vw;
	// align-self: flex-start;
}

.reports-content {
	width: 100%;
	padding: 0 20px;
	height: 95%;
	display: flex;
	flex-direction: column;
	//justify-content: space-between;
	align-items: center;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
	}
	
	&::-webkit-scrollbar-thumb {
		border-radius: 3.5px;
		outline: none;
	}
}

.reports-header {
	width: 100%;
	margin-bottom: 25px;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.display-wrapper {
	cursor: pointer;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		height: 100%;
		object-fit: contain;
	}
}

.reports-header-right {
	// width: 15%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	// height: 80%;
}

.reports-title {
	font-size: 1.5vw;
	color: #3f4140;
	font-weight: bold;
	margin: 0;
}

.reports-title-border {
	height: 40px;
	width: 2px;
	background-color: #eaf0f7;
	margin: 0 15px 0 15px;
}

.reports-amount {
	font-size: 1vw;
	color: #3f4140;
	margin: 0;
}

.reports-header-left {
	// width: 40%;
	// height: 60%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.filterByBox {
	position: relative;
	width: 190px;
	height: 35px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-right: 25px;
}

.filterBoxTitle {
	cursor: pointer;
	width: 100%;
	height: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #e4e4e4;
	border-radius: 20px;
	padding: 0 0.5vw 0 0.5vw;

	p {
		margin: 0;
	}
}

.dropdown {
	border-radius: 10px;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: baseline;
	width: 100%;
	background-color: white;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
	padding: 0 0.5vw 0 0.5vw;
}

.dropdown-item {
	margin-bottom: 5px;
	width: 100%;
	cursor: pointer;

	&:hover {
		background-color: #eaf0f7;
	}
}

.reportsWrapper-table {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 3%;
	//overflow: auto;
	// overflow-y: auto;
	padding: 5px 0 5px 5px;
	grid-auto-rows: 228px;
	margin-bottom: 20px;
}

.reportsWrapper-row {
	width: 100%;
	padding: 15px 0 15px 15px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: flex-start;
}

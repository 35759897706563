.accordion {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.accordion-title-wrapper {
	//background-color: #eaeaea;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 12px;
	text-decoration: none;
}

.accodrion-title {
	width: 85%;
	margin: 10px 0 10px 0;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: #3f4140;
	font-size: 20px;

	i {
		color: #3f4140;
		transition: all 0.3s;
		font-size: 1vw;
	}
}

.active-accodrion-title {
	@extend .accodrion-title;
	color: #273867;
	font-weight: bold;
}

.upsidedown {
	transform: rotate(180deg);
}

.link-wrapper {
	margin-bottom: 1.3vw;
}

.openLinks {
	overflow: hidden;
	max-height: 1000px;
	transition: max-height 1s ease-in-out;
	display: flex;
	flex-direction: column;
}

.openLinks[aria-expanded='true'] {
	max-height: 0px;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordion-link {
	text-decoration: none;
	color: #3f4140;
	font-size: 15px;
	margin-right: 1.5vw;
	margin-bottom: 12px;
}

.active-link {
	color: #273867;
	font-weight: bold;
}

.underline-link {
	width: 30%;
	height: 3px;
	border-radius: 7px;
	background-color: #273867;
}

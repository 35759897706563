.header {
  width: 100%;
  height: 80px;
  border-bottom: 1.5px solid #ededec;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 1rem;
}
.header-right {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 40%;
}
.header-logos-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 15px;
}
.logo-div {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    height: 70%;
    object-fit: contain;
  }
}
.header-search {
  height: 100%;
  width: 50%;
  border-right: 1.5px solid #ededec;
  border-left: 1.5px solid #ededec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.header-search-input {
  height: 80%;
  width: 70%;
  border: none;
  outline: none;
  font-size: 1.2vw;
  font-family: 'Almoni Neue DL 4.0 AAA', sans-serif;
}
.header-search-input::placeholder {
  font-size: 1.1vw;
  font-weight: bold;
  color: #acadad;
}
.magnify {
  margin-left: 10px;
  color: #3f4140;
}
.header-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-right: 1px solid #ededec;
  padding: 0 2rem 0 1rem;

  @media only screen and (max-width: 1570px) {
    justify-content: end;
  }
}
.header-time {
  color: #3f4140;
  font-size: 2.5vw;
  // margin-bottom: 5px;
  letter-spacing: 3px;
  font-weight: normal;
  font-weight: 500;
  height: 50%;
}
.header-date {
  color: #c8c8c9;
  height: 34%;
}

.report-row {
  cursor: pointer;
  width: 100%;
  min-height: 10vh;
  max-height: 10vh;
  border-radius: 5px;
  border: solid 1px #d0d0d0;
  background-color: #fff;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5vw 0 1vw;
  -webkit-animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.row-right {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 50%;
}

.row-favorite-wrapper {
  cursor: pointer;
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 17px;
    width: 17px;
    filter: invert(23%) sepia(9%) saturate(116%) hue-rotate(98deg) brightness(93%) contrast(86%);

    &:hover {
      filter: invert(99%) sepia(64%) saturate(260%) hue-rotate(149deg) brightness(113%) contrast(100%);
    }
  }

  i {
    font-size: 15px;
    color: #3f4140;
  }
}

.row-titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60%;
  align-self: center;

  p {
    margin: 0;
  }
}

.row-title {
  color: #5f5f5f;
  font-weight: bold;
  font-size: 1.1vw;
  line-height: 1;
}

.row-extra {
  color: #5f5f5f;
  font-size: 0.9vw;
}

.row-date {
  color: #5f5f5f;
  font-size: 0.9vw;
}
.openSideNav {
  position: absolute;
  z-index: 4;
  width: 235px;
  height: 100%;
  background-color: #f3f3f3;
  transform: translateX(235px);
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.openSideNav-open {
  transform: translateX(-115px);
}
.openNavTitle {
  position: relative;
  width: 100%;
  padding: 1rem 1.3rem 1rem 1rem;
  color: #3f4140;
  font-size: 21px;
  font-weight: bold;
  border-bottom: 1px solid #d8d8d8;
}
.closeSideNav {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #f3f3f3;
  background-color: white;
  border-radius: 50%;
  width: 1.7vw;
  height: 1.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  cursor: pointer;
}
.second-level {
  width: 100%;
  margin-top: 1rem;
  // padding: 1rem 1.3rem 1rem 1rem;
}

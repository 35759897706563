.homePage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  -webkit-animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.home-right {
  position: relative;
  z-index: 10;
  width: 35%;
  height: 100%;
  box-shadow: 0 20px 20px 0 rgba(204, 204, 204, 0.5);
  background-color: #f4f7fb;
}

.bottom-graps {
  position: absolute;
  bottom: -4px;
  text-align: center;
  width: 100%;

  img {
    object-fit: contain;

    @media only screen and (max-width: 1750px) {
      width: 100%;
    }
  }
}

.home-right-content {
  padding-top: 5vw;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: flex-start;
  height: 70%;
  width: 75%;
  margin: 0 auto;
}

.home-logo-wrapper {
  height: 16%;
  width: 47%;
  margin-bottom: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.userName {
  display: flex;
  flex-direction: column;
  margin-top: -2vw;
  margin-bottom: 30px;

  p {
    margin: 0;
    color: #3f4140;
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
  }
}

.home-search-wrapper {
  width: 100%;
  margin-bottom: 40px;
  border-radius: 50px;
  box-shadow: 0 4px 10px -10px #a2a3a6;
  background-color: #fff;
  padding: 0 30px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.home-serach-input {
  outline: none;
  border: none;
  width: 100%;
  font-size: 20px;
  font-family: 'Almoni Neue DL 4.0 AAA', sans-serif;
}

.home-serach-input::placeholder {
  color: #acadad;
  font-weight: 400;
  font-size: 20px;
}

.home-links {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #07b6f3;
    direction: ltr;
    text-align: right;
  }
}

.home-left {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}

.report-group::-webkit-scrollbar {
  width: 8px; // for vertical scroll bar
  height: 8px; // for horizontal scroll bar
}

.birthday-wrapper {
  position: relative;
  width: 80%;
  height: 16vh;
  background-image: url('../../assets/birthday.svg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: visible;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.close-birth {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: white;
  border: 1px solid #3bafdf;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw;
  color: #3bafdf;
}

.birtday-content {
  width: 80%;
  height: 85%;
  //background-color: green;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.birth-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5vw;
  font-weight: 200;
  line-height: 2.5rem;
}

.birth-num {
  // font-family: 'almoni';
  font-size: 3vw !important;
  font-weight: 100;
}

.birth-title {
  color: white;
  font-size: 2.5vw;
  font-weight: bold;
}

.home-favorites {
  width: 90%;
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  margin: 0 auto;
}

.home-favorites-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  p {
    color: #3f4140;
    margin: 0;
    font-size: 1.2vw;
  }
}

.reports-search-wrap {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3%;
  padding: 50px;
  overflow-y: auto;
}

.reportsWrapper-table {
  a {
    text-decoration: none;
  }
}

.home-favorites-reports {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3%;

  a {
    text-decoration: none;
  }
}

.show-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  i {
    font-size: 15px;
    margin-right: 5px;
    color: #000;
  }
}

.noResults {
  color: #3f4140;
  margin: 0;
  font-size: 1.2vw;
}

.sideIcon {
  margin-bottom: 5px;
}

.navLinkWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    transition: all 0.3s ease-in-out;
    max-width: 45px;
  }
}

.iconTitle {
  // margin: 2px 0 0 0;
  font-size: 15px;
  max-width: 80%;
  text-align: center;
  color: white;
  line-height: 12px;
  // margin-top: 10px;
  margin-top: 6px;
  margin-bottom: 5px;
}

.navLinkWrapper {
  &.active {
    img {
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(59deg)
        brightness(103%) contrast(101%);
    }
  }

  &:hover {
    img {
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(59deg)
        brightness(103%) contrast(101%);
    }
  }
}

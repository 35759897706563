.reportBox {
  cursor: pointer;
  position: relative;
  height: 24vh;
  min-width: 35%;
  display: flex;
  flex-direction: column;
  // box-shadow: 2px 3px 5px 0px rgba(204, 204, 204, 0.356);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.emptyStarWrapper {
  cursor: pointer;
  position: absolute;
  top: 5%;
  left: 5%;
  height: 10%;
  z-index: 5555;
  img {
    height: 100%;
    object-fit: contain;
    filter: invert(23%) sepia(9%) saturate(116%) hue-rotate(98deg)
      brightness(93%) contrast(86%);
  }
  i {
    color: #3f4140;
    font-size: 0.95vw;
  }
}
.hover-favorite {
  z-index: 3;
  i {
    color: white;
  }
  img {
    filter: invert(99%) sepia(64%) saturate(260%) hue-rotate(149deg)
      brightness(113%) contrast(100%);
  }
}
.reportBox-top {
  height: 75%;
  overflow: hidden;
  position: relative;
}
.iframe-review {
  height: 100%;
  width: 100%;
  background-color: #ddd;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.reportBox-breadCrumbs {
  font-size: 0.8vw;
  color: black;
  padding: 0 20px 10px 10px;

  p {
    margin: 0;
    padding: 0;
  }
}

.reportBox-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  align-items: center;
  height: auto;
  flex: 1;
}
.box-title {
  color: #5f5f5f;
  font-size: 15px;
  margin: 0;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 60%;
  // max-width: 60%;
  font-family: 'Assistant', sans-serif;

  @media only screen and (max-width: 1200px) {
    font-size: 10px;
  }
}
.box-date {
  color: #5f5f5f;
  margin: 0;
  font-size: 0.7vw;
  font-family: 'Assistant', sans-serif;
}
.hover-img {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  text-align: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showHover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.658);
}
.editBtn {
  color: white;
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid white;
  width: 27%;
  height: 32%;
  cursor: pointer;
}
.editBtn:active {
  transform: translateY(4px);
}
